import React from 'react'
import Layout from '../components/Layout'
// import SEO from '../components/SEO';
import { Hero, Cta } from '../acf';

const NotFoundPage = () => (
  <Layout>
    {/* <SEO title={`404 | ${siteTitle}`} /> */}
    <Hero subheading="Error 404" heading={`Page Not Found`} blurb={`The page you are looking for could not be found. Please check the address and try again.`} />
  </Layout>
)

export default NotFoundPage
